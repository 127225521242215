import React, { useEffect } from "react";
import Portfolio from "../Pages/portfolio";

import { Route, Routes, useLocation } from "react-router-dom";

import ReactGA from "react-ga4";

const Router = () => {
  const location = useLocation();
  useEffect(() => {
    ReactGA.send({
      hitType: "pageview",
      page: location.pathname,
    });
  }, [location]);

  return (
    <div>
      <Routes>
        <Route path="*" element={<Portfolio />} />
      </Routes>
    </div>
  );
};

export default Router;
