import React from "react";
import { BrowserRouter } from "react-router-dom";
import Router from "./Routes/router";
import Main from "./Components/Layout/main";
import "./App.css";

import ReactGA from "react-ga4";
import TagManager from "react-gtm-module";

ReactGA.initialize("G-8JJ0114X05");
TagManager.initialize({
  gtmId: "GTM-WBZXRWXC",
});

function App() {
  return (
    <div>
      <BrowserRouter>
        <Main>
          <Router />
        </Main>
      </BrowserRouter>
    </div>
  );
}

export default App;
