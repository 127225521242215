import React, { useState } from "react";
import Navbars from "./navbar";
import { Layout } from "antd";

const { Content } = Layout;

const Main = ({ children }) => {
  const [selectedLink, setSelectedLink] = useState(window.location.pathname);

  return (
    <Layout
      style={{
        background: "transparent",
      }}
    >
      <Navbars selectedLink={selectedLink} setSelectedLink={setSelectedLink} />
      <div className="main-container">
        <Layout
          style={{
            backgroundColor: "transparent",
          }}
        >
          <Content>{children}</Content>
        </Layout>
      </div>
    </Layout>
  );
};

export default Main;
