import React, { useEffect, useState } from "react";
import companyLogo from "../../Assets/Images/white and purple.png";
import { useNavigate } from "react-router-dom";
import "../../Assets/Styles/layout.css";

const Navbars = ({ setSelectedLink }) => {
  const navigate = useNavigate();
  const [navbar, setNavbar] = useState(false);

  const handleLogoClick = () => {
    navigate("/home");
    setSelectedLink("/home");
  };

  useEffect(() => {
    const changeBackground = () => {
      if (window.scrollY >= 20) {
        setNavbar(true);
      } else {
        setNavbar(false);
      }
    };

    changeBackground();

    window.addEventListener("scroll", changeBackground);
  }, []);

  return (
    <div>
      <nav className={navbar ? "navbar-active navbar" : "navbar-not-active navbar"}>
        <div onClick={handleLogoClick} className="navbar-logo">
          <img src={companyLogo} alt="CouSync Logo" />
        </div>
      </nav>
    </div>
  );
};

export default Navbars;
